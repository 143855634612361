import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LazyTranslateLoader } from './services/translation/lazy-translate-loader';
import { GraphqlModule } from './graphql/graphql.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        GraphqlModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useClass: LazyTranslateLoader,
            },
        }),
    ],
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}
