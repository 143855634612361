import gql from 'graphql-tag';

export const getProgramSkillGraphs = gql`
    query getProgramSkillGraphs($programId: Int!) {
        getProgramSkillGraphs(programId: $programId) {
            id
            dateCreated
            dateUpdated
            isActive
            name
            version
        }
    }
`;
